/**
 * Created by ethannguyen on 25/08/2016.
 */

define(['app', 'ePopup'], function(app, ePopup) {
  

  /**
   * Bundle Class
   */
  class bundle {
    constructor() {
      const self = this;
      self.bundle = document.querySelector('[data-component="ebundleFit"]');
      self.bundleLists = self.bundle.querySelectorAll('.bundleList');
      self.lists = [];
      self.buyNow = self.bundle.querySelector('.js-buynow');
      self.bundleVoucher = self.bundle.querySelector('.js-bundle-voucher');
      self.bundleBtn = new bundleButton(self);

      if (self.bundle.querySelector('.bundleProduct_bundleDetails_description_hiddenField')) {
        self.bundleGift = self.bundle.querySelector('.bundleProduct_bundleDetails_description_hiddenField').innerHTML;
      }

      for (let i = 0; i < self.bundleLists.length; i++) {
        self.lists[i] = new bundleList(self, self.bundleLists[i]);
      }

      self.updateBundle();
    }

    updateBundle() {
      const self = this;
      self.checkFulfill();
      if (self.fulfill) {
        self.selectedItems = self.lists.map((list) => {
          return list.selectedItems;
        });
        self.updateBuyList();
      }
    }

    updateBuyList() {
      const self = this;
      let buyHref = '/my.basket?buylist=' + self.selectedItems.map((item) => {
        if (item) return item;
      }).join(',');
      if (self.bundleGift)
        buyHref += `,${self.bundleGift}`;
      if (self.bundleVoucher)
        buyHref += `&applyCode=${self.bundleVoucher.getAttribute('data-voucher')}`;
      self.buyNow.href = buyHref;
    }

    checkFulfill() {
      const self = this;
      app.element.removeClass('incomplete', self.bundle);
      self.buyNow.removeAttribute('href');
      self.fulfill = true;
      for (let i = 0; i < self.lists.length; i++) {
        if (self.lists[i].optional === false && self.lists[i].fulfill === false)
          self.fulfill = false;
      }
    }
  }

  /**
   * Bundle List Class
   */
  class bundleList {
    constructor(bundle, listEl) {
      const self = this;
      self.bundle = bundle;
      self.list = listEl;
      self.fulfill = false;
      self.optional = app.element.hasClass('bundleList_optional', self.list);
      self.bundleSections = self.list.querySelectorAll('.bundleList_item');
      self.sections = [];

      for (let i = 0; i < self.bundleSections.length; i++) {
        self.sections[i] = new bundleSection(self, self.bundleSections[i]);
      }
      self.updateList();
    }

    updateList() {
      const self = this;
      self.fulfill = true;
      self.selectedItems = [];
      for (let i = 0; i < self.sections.length; i++) {
        if (self.sections[i].selectedItem) self.selectedItems.push(self.sections[i].selectedItem);
      }

      for (let i = 0; i < self.sections.length; i++) {
        if (self.sections[i].optional === false && !self.sections[i].selectedItem)
          self.fulfill = false;
      }

      self.bundle.updateBundle();
    }
  }

  /**
   * Bundle Section Class
   */
  class bundleSection {
    constructor(list, sectionEl) {
      const self = this;
      self.list = list;
      self.section = sectionEl;
      self.optional = false;
      self.selection = self.section.querySelector('select');
      self.infoBtn = self.section.querySelector('.bundleList_icon');

      if (app.element.hasClass('bundleList_optional', self.list.list))
        self.optional = true;
      let selection = self.selection.options[self.selection.selectedIndex];
      if (selection.hasAttribute('data-product-id')) {
        self.selectedItem = selection.getAttribute('data-product-id');
        if (self.section.querySelector(`.bundleList_info [data-product-id="${self.selectedItem}"]`)) {
          self.info = self.section.querySelector(`.bundleList_info [data-product-id="${self.selectedItem}"]`);
          if (self.info.innerHTML.length > 20) app.element.removeClass('hidden', self.infoBtn);
          else app.element.addClass('hidden', self.infoBtn);
        } else {
          app.element.addClass('hidden', self.infoBtn);
        }
        app.element.addClass('selected', self.selection);
        app.element.addClass('selected', self.section);
      }

      self.updateSection();
      self.infoHandler();
    }

    updateSection() {
      const self = this;
      self.selection.addEventListener('change', (e) => {
        if (self.selection.options[self.selection.selectedIndex].hasAttribute('data-product-id')) {
          self.selectedItem = self.selection.options[self.selection.selectedIndex].getAttribute('data-product-id');
          if (self.section.querySelector(`.bundleList_info [data-product-id="${self.selectedItem}"]`)) {
            self.info = self.section.querySelector(`.bundleList_info [data-product-id="${self.selectedItem}"]`);
            if (self.info.innerHTML.length > 20) app.element.removeClass('hidden', self.infoBtn);
            else app.element.addClass('hidden', self.infoBtn);
          } else {
            app.element.addClass('hidden', self.infoBtn);
          }
          app.element.addClass('selected', self.selection);
          app.element.addClass('selected', self.section);
        } else {
          self.selectedItem = undefined;
          app.element.addClass('hidden', self.infoBtn);
          app.element.removeClass('selected', self.selection);
          app.element.removeClass('selected', self.section);
        }
        self.list.updateList();
      });
    }

    infoHandler() {
      const self = this;
      self.infoBtn.addEventListener('click', (e) => {
        if (self.info) new ePopup(self.info, 'bundleProduct_info-popup', true, true);
      });
    }
  }

  /**
   * Bundle Button Class
   */
  class bundleButton {
    constructor(bundle) {
      const self = this;
      self.bundle = bundle;
      self.bundleBtn = self.bundle.bundle.querySelector('.js-buynow');
      self.buttonHandler();
    }

    buttonHandler() {
      const self = this;
      self.bundleBtn.addEventListener('click', (e) => {
        if (self.bundle.fulfill === false) app.element.addClass('incomplete', self.bundle.bundle);
      });
    }
  }

  if (document.querySelector('[data-component="ebundleFit"]')) {
    return new bundle();
  } else {
    return bundle;
  }

});
